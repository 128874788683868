import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import API from '../../services/API';
import Loader from '../Loader';

const AdminHome = () => {
    const [lang, setLang] = useState({ 'db': 'spanish', 'url': 'es' });
    const [completed, setCompleted] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
    const fetchData = async () => {
        const result = await API.get(`/completed_lang?lang=${lang.db}`);
        setCompleted(result.data);
        setLoading(false);
    };
    fetchData();
    }, [lang]);

    if (loading) {
    return <Loader />;
    }

    return (
    <div className="adminPage">
        <div>
        <header className="flex justify-between items-center mb-4">
            <Link to="/admin">
            <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                onClick={() => setLang(null)}
            >
                Back To Languages
            </button>
            </Link>
            <h1 className="text-2xl font-bold">
            Admin {lang.db.toUpperCase()}
            </h1>
            <div></div>
        </header>

        <div className="flex flex-wrap justify-center">
            {new Array(500).fill('').map((_, i) => {
            const bg = completed[i]?.completed ? 'bg-green-500' : 'bg-blue-500';
            const hover = completed[i]?.completed ? 'hover:bg-green-600' : 'hover:bg-blue-600'; 
            return (
                <Link
                to={{ pathname: `/admin/${lang.url}/${i}` }}
                key={i}
                className="block mx-2 my-2"
                style={{ flexBasis: '5%' }}
                >
                <button
                    className={`adminBtn ${bg} ${hover} text-gray-800 font-bold py-2 px-4 rounded`}
                >
                    Day {i}
                </button>
                </Link>
            );
            })}
        </div>
        </div>
    </div>
    );
};

export default AdminHome;
