import { Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {Auth} from '@aws-amplify/auth'; 
import Loader from './components/Loader';

export function RequireAdminAuth({ children }) {
  const [authorized,setAuthortized] = useState(false)
  const [loading,setLoading] = useState(true)

  useEffect(() => {
    // Get the signed-in user
    Auth.currentAuthenticatedUser({bypassCache : true})
      .then(user => {
        // Check the user's groups
        const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
        setAuthortized(groups.includes('admin'))
        setLoading(false)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  if (loading) {
    return <Loader/>
  }

  if (!authorized && !loading) {
    // Redirect to a different page or show an access denied message
    // based on your requirement
    return <Navigate to="/" />;
  } 
    
  if (authorized && !loading) {
  return children;
  }
  
}