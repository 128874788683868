import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import API from '../../services/API';
import Loader from '../Loader';
import langs from '../langs'

const UpdateQForm = ({ data , setData , lang }) => {
  const [editingId, setEditingId] = useState(null);
  const [editedQform, setEditedQform] = useState('');
  const [editedWordIndex, setEditedWordIndex] = useState(null);
  const [editedWordValue, setEditedWordValue] = useState('');
  const [loading, setLoading] = useState(false)
  const urlLang = langs[lang]

  const handleQformEdit = (id, qform) => {
    setEditingId(id);
    setEditedQform(qform);
  };

  const handleQformSave = async (id) => {
    // Perform the save operation with the editedQform value
    console.log(`Save qform with id ${id}: ${editedQform}`);
    const updatedData = data.highlited.map((item) => {
        if (item.id === id) {
          return { ...item, qform: editedQform };
        }
        return item;
      });

      setLoading(true)

      await API.post(`/update_q_forms?lang=${lang}` ,{ data : {
        'id' : id,
        'words': updatedData.find((item) => item.id === id).words.map(word => word['word']),
        'qform' : editedQform 
      }
    })

    // Update the state with the updated data
    setData({...data , 'highlited' :updatedData});

    // Clear the editing state
    setEditingId(null);
    setEditedQform('');
    setLoading(false)
  };

  const handleWordEdit = (id, wordIndex, word) => {
    setEditingId(id);
    setEditedWordIndex(wordIndex);
    setEditedWordValue(word);
  };

  const handleWordSave = async (id) => {
    // Perform the save operation with the editedWordIndex and editedWordValue
    console.log(`Save word with id ${id}, wordIndex ${editedWordIndex}: ${editedWordValue}`);
    const updatedData = data.highlited.map((item) => {
        if (item.id === id) {
          const updatedWords = [...item.words];
          updatedWords[editedWordIndex] = editedWordValue;
          return { ...item, words: updatedWords };
        }
        return item;
      });
      setLoading(true)
    await API.post(`/update_q_forms?lang=${lang}` , {data :{
        'id' : id,
        'words': updatedData.find((item) => item.id === id).words.map(word => word['word']),
        'qform' : updatedData.find((item) => item.id === id).qform, 
    }
    })

    // Update the state with the updated data
    setData({...data , 'highlited' :updatedData});
    // Clear the editing state
    setEditingId(null);
    setEditedWordIndex(null);
    setEditedWordValue('');
    setLoading(false)
  };

  const handleWordAdd = (id, index) => {
    setEditingId(id);
    setEditedWordIndex(index);
    setEditedWordValue('');
  };

  const handleWordAddSave = async (id, index) => {
    setLoading(true)
    // Perform the save operation to add the new word at the specified index
    console.log(`Add word with id ${id} at index ${index}: ${editedWordValue}`);
    // Find the item in the data array and add the new word at the specified index
    const updatedData = data.highlited.map((item) => {
        if (item.id === id) {
          const updatedWords = [...item.words];
          updatedWords.splice(index, 0, editedWordValue);
          return { ...item, words: updatedWords };
        }
        return item;
      });

    await API.post(`/update_q_forms?lang=${lang}` , {data : {
        'id': id,
        'words': updatedData.find((item) => item.id === id).words.map(word => word['word']),
        'qform' : updatedData.find((item) => item.id === id).qform, 
    }
    })

    // Update the state with the updated data
    setData({...data , 'highlited' :updatedData});
    // Clear the editing state
    setEditingId(null);
    setEditedWordIndex(null);
    setEditedWordValue('');
    setLoading(false)
  };


  // New function for handling word deletion
  const handleWordDelete = async (id, wordIndex) => {
    // Update the word list by removing the word at wordIndex
    const updatedData = data.highlited.map((item) => {
        if (item.id === id) {
          const updatedWords = [...item.words];
          updatedWords.splice(wordIndex, 1);
          return { ...item, words: updatedWords };
        }
        return item;
      });

      console.log(updatedData.find((item) => item.id === id).words.map(word => word['word']))

    await API.post(`/update_q_forms?lang=${lang}` , {data :{
        'id' : id,
        'words': updatedData.find((item) => item.id === id).words.map(word => word['word']),
        'qform' : updatedData.find((item) => item.id === id).qform, 
    }
    })

    // Update the state with the updated data
    setData({...data , 'highlited' :updatedData});

  };

  return (
    <div className="container mx-auto">
    <Link to={`/admin/${urlLang}`} className="mb-4 inline-block bg-blue-500 px-4 py-2 text-white rounded">Back</Link>
      {data.highlited.map((item,i) => (
        <div key={item.id} className="p-4 mb-4 border rounded">
          <div className="mb-2">
            <strong>qform:</strong>
            {editingId === item.id ? (
            
              <div className="flex items-center">
                {loading? <Loader />:
                (<><input
                  type="text"
                  className="mr-2 px-2 py-1 border rounded"
                  value={editedQform}
                  onChange={(e) => setEditedQform(e.target.value)}
                />
                <button
                  className="px-2 py-1 bg-green-500 text-white rounded"
                  onClick={() => handleQformSave(item.id)}
                >
                  Save
                </button>
                </>)
                }
              </div>
            ) : (
              <span
                className="cursor-pointer underline"
                onClick={() => handleQformEdit(item.id, item.qform)}
              >
                {item.qform}
              </span>
            )}
          </div>
          <div className="mb-2">
            <strong>english:</strong> {data.sentences[i].englishs}
          </div>
          <div className="mb-2">
            <strong>spanish:</strong> {data.sentences[i].spanishs}
          </div>
          <div className="mb-2">
            <strong>sentence:</strong> {item.sentence}
          </div>
          <div className="mb-2">
            <strong>words:</strong>
            {item.words.map((word, wordIndex) => (
              <span key={wordIndex}>
                {editingId === item.id && editedWordIndex === wordIndex ? (
                  <div className="flex items-center">
                    <input
                      type="text"
                      className="mr-2 px-2 py-1 border rounded"
                      value={editedWordValue}
                      onChange={(e) => setEditedWordValue(e.target.value)}
                    />
                    <button
                      className="px-2 py-1 bg-green-500 text-white rounded"
                      onClick={() => handleWordSave(item.id)}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                <div className="flex items-center">
                  <button
                    className="mr-2 px-2 py-1 bg-blue-500 text-white rounded"
                    onClick={() => handleWordEdit(item.id, wordIndex, word)}
                  >
                    {word}
                  </button>
                  <button
                  className="mr-2 px-2 py-1 bg-red-500 text-white rounded"
                  onClick={() => handleWordDelete(item.id, wordIndex)}
                >
                  Delete
                </button>
                </div>
                )}
              </span>
            ))}
            <button
              className="px-2 py-1 bg-blue-500 text-white rounded"
              onClick={() => handleWordAdd(item.id, item.words.length)}
            >
              Add Word
            </button>
          </div>
          {editingId === item.id && editedWordIndex === item.words.length && (
            <div className="flex items-center">
              <input
                type="text"
                className="mr-2 px-2 py-1 border rounded"
                value={editedWordValue}
                onChange={(e) => setEditedWordValue(e.target.value)}
              />
              <button
                className="px-2 py-1 bg-green-500 text-white rounded"
                onClick={() => handleWordAddSave(item.id, item.words.length)}
              >
                Save
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default UpdateQForm;
