// components/Layout.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from '@aws-amplify/auth';

// stripe
import Stripe from './Stripe/Stripe'

function CustomNavLink({ to, children , name }) {
  const location = useLocation();
  let isActive = false;
  
  if (name==='home' && (location.pathname === '/' || location.pathname.includes('/lesson'))) {
    isActive = true
  } else if (name==='admin' && location.pathname.includes('/admin')) {
    isActive = true
  }  else if (location.pathname === to ) {
    isActive = true
  } else {
    isActive = false
  }

  const activeStyle = {
    backgroundColor: isActive ? '#E5E7EB' : '',
  };

  return (
    <Link to={to} className="p-4 hover:bg-gray-200 border-b border-gray-300" style={activeStyle}>
      {children}
    </Link>
  );
}

export function Layout() {
  const { route, signOut } = useAuthenticator((context) => [context.route, context.signOut]);
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    Auth.currentAuthenticatedUser({bypassCache : true})
      .then((user) => {
        const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
        setGroups(groups);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  function logOut() {
    signOut();
    navigate('/login');
  }

  return (
    <>
      <nav className="flex flex-col h-screen bg-gray-100 w-full border-r border-gray-500">
        <CustomNavLink to="/" name={'home'}>Home</CustomNavLink>
        {groups.includes('admin') && <CustomNavLink to="/admin" name={'admin'}>Admin</CustomNavLink>}
        {route !== 'authenticated' ? (
          <CustomNavLink to="/login">Login</CustomNavLink>
        ) : (
          <Link
            to="/logout"
            onClick={() => logOut()}
            className="p-4 hover:bg-gray-200 border-b border-gray-300"
          >
            Logout
          </Link>
        )}
        {groups.includes('premium') ? <CustomNavLink to={process.env.REACT_APP_STRIPE_MANAGE_URL} name={'profile'}>Manage Profile</CustomNavLink>: <Stripe />}
      </nav>
    </>
  );
}
