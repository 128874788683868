import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../services/API';
import Loader from '../Loader';
import { useAuthenticator } from '@aws-amplify/ui-react';

const QuestionForm = ({ questions, day , lang}) => {
    const navigate = useNavigate();
    const user = useAuthenticator((context) => [context.user]).user.attributes.sub;
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState(questions[currentQuestion].qform.split('').map(word => word ==='_' ? {value: '_', display: '\u00A0',check : true} : {value: word, display: word , check: false}));
    const [loading, setLoading] = useState(false);
    const [shakeInput, setShakeInput] = useState(false);
    const [showWordBank, setShowWordBank] = useState(true);
    const [wordBank, setWordBank] = useState(questions[currentQuestion].updated_word_array);
    const [showPopup, setShowPopup] = useState(true);

    const toggleWordBank = () => {
    setShowWordBank(!showWordBank);
    };

    useEffect(() => {
    const savedIndex = localStorage.getItem(`nextQuestionIndex-${lang}-${day}-${user}`);
    if (savedIndex) {
        setCurrentQuestion(parseInt(savedIndex, 10));
        setAnswers(questions[savedIndex].qform.split('').map(word => word ==='_' ? {value: '_', display: '\u00A0',check : true} : {value: word, display: word , check: false}))
    }
    }, [lang,day,user,questions]);    

    const handleSubmit = async () => {
    // Check if the answer is correct
    const currentAnswer = answers.filter(obj => obj.check).map(word => word.value);
    const isCorrect = JSON.stringify(currentAnswer) === JSON.stringify(questions[currentQuestion].wordarray);

    if (isCorrect) {
        // Check if all questions have been answered correctly
        if (currentQuestion === questions.length - 1) {
        try {
            setLoading(true);
            // Hit the POST API for completed lesson
            await API.post('/complete_lesson', { data: { day } });

            // Remove the index if we've completed it
            localStorage.removeItem(`nextQuestionIndex-${lang}-${day}-${user}`);

            // Optionally, you can perform any additional actions or display a success message here
            navigate('/');
        } catch (error) {
            console.error('Failed to mark lesson as completed:', error);
        } finally {
            setLoading(false);
        }
        } else {
         // Move to the next question
         setCurrentQuestion(currentQuestion + 1);
         setAnswers(questions[currentQuestion+1].qform.split('').map(word => word ==='_' ? {value: '_', display: '\u00A0',check : true} : {value: word, display: word , check: false}))
         setWordBank(questions[currentQuestion +1].updated_word_array);
         // Save the index of the next question in local storage
         localStorage.setItem(`nextQuestionIndex-${lang}-${day}-${user}`, (currentQuestion + 1).toString());
        }
 
    } else {
        // Shake the input field
        setShakeInput(true);
        setTimeout(() => {
        setShakeInput(false);
        }, 500);
    }
    };

    const handleWordClick = (e, word,i,add) => {
        // then we are adding to the answers index
        const elementWord = e.target.value;
        if (elementWord === '_') return
        const newWordBank = [...wordBank];
        const newAnswers = [...answers];
        if (add === true) {
            const nextReplace = newAnswers.map(obj => obj.value).indexOf('_')
            newAnswers[nextReplace].value = word
            newAnswers[nextReplace].display = word
            newWordBank.splice(i,1)
        }
        else if (add === false) {
            newWordBank.push(elementWord)
            newAnswers[i].value = '_'
            newAnswers[i].display = '\u00A0'
        };
      
        setWordBank(newWordBank);
        setAnswers(newAnswers);
      };

      const handleInputChage = (e, index) => {
        const newAnswers = [...answers];
        // const nextReplace = newAnswers.map(obj => obj.value).indexOf('_')
        const word = e.target.value
        newAnswers[index].value = word
        newAnswers[index].display = word
        setAnswers(newAnswers)
      }


    const isFirstQuestion = currentQuestion === 0;

    if (loading) {
    return <Loader />;
    }

    return (
    <div className="flex items-center justify-center h-screen">
        {isFirstQuestion && showPopup && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="p-4 bg-white rounded-md shadow-lg relative">
            <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={() => setShowPopup(false)}
            >
                X
            </button>
            <p className="text-xl font-bold mb-2">Welcome to the Lesson!</p>
            <p>Today's Word is {questions[currentQuestion].word}</p>
            </div>
        </div>
        )}
        <div className="flex flex-col items-center">
        {currentQuestion < questions.length ? (
            <>
            <div className="mb-4">
            {questions[currentQuestion].englishs} 
            </div>
            <div className="mb-4">
                {questions[currentQuestion].way}
            </div>
            <form>
            <div className="mb-4">
                {questions[currentQuestion].qform.split('').map((char, index) => (
                <React.Fragment key={index}>
                    {char === '_' ? (
                    showWordBank ? (
                        <button
                        id={`answer-input${index}`}
                        type="button"
                        value={answers[index].value}
                        onClick={(e) => handleWordClick(e,'',index,false)}
                        className={`${shakeInput ? 'shake' : ''} bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded m-2`}
                        >
                        {answers[index].display}
                        </button>
                    ) : (
                        <input
                        id={`answer-input${index}`}
                        type="text"
                        placeholder="Enter your answer"
                        onChange={(e) => handleInputChage(e, index)}
                        className={`${shakeInput ? 'shake' : ''} bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded m-2`}
                        />
                    )
                    ) : (
                    <span>{char}</span>
                    )}
                </React.Fragment>
                ))}
            </div>
            <div>
                {showWordBank ? (
                <div>
                    {wordBank.map((word, index) => (
                    <button
                        key={index}
                        type='button'
                        onClick={(e) => handleWordClick(e,word,index,true)}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded m-2"
                    >
                        {word}
                    </button>
                    ))}
                </div>
                ) : null}
            </div>
            <button
                type="button"
                onClick={toggleWordBank}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded m-2 transition-colors duration-300 ease-in-out"
            >
                {showWordBank ? 'Use Input' : 'Use Word Bank'}
            </button>

            <button
                type="button"
                onClick={handleSubmit}
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded m-2 transition-colors duration-300 ease-in-out"
            >
                Submit
            </button>
            </form>
            </>
        ) : (
            <div className="mt-8">
            <p className="text-lg font-medium mb-4">All questions answered!</p>
            <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                onClick={() => navigate('/')}
            >
                Redirect to Home
            </button>
            </div>
        )}
        </div>
    </div>
    );
};

export default QuestionForm;
