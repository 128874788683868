import React ,{useEffect , useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import API from '../../services/API';
import Loader from '../Loader';
import QuestionForm from './QuestionForm';

const Lesson = () => {
  const { day , lang } = useParams();
  const validDay = day>=0 && day<=500
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const langMap = { 'es' : 'spanish'}

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProgress = await API.get(`/user_progress?day=${day}`)
        // if they don't have access to it
        if (userProgress.data.completed === null) {navigate('/')}
        const { data } = await API.get(`/questions?lang=spanish&day=${day}`);
        setQuestions(data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch questions:', error);
        setLoading(false);
      }
    };

    validDay ? fetchData() : navigate('/')
  }, [day, validDay , navigate]);


  if (loading) {
    return <Loader />;
  }

  return <QuestionForm questions={questions} day={day} navigate={navigate} lang={langMap[lang]}/>;
};

export default Lesson;