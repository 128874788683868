// components/Home.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import API from "../services/API";

export function Home() {
    const days = new Array(500).fill("");
    const [dayCompleted, setDayCompleted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [resetDay, setResetDay] = useState(null);
    const [loading, setLoading] = useState(true);
    // const [premium , setPremium] = useState(false)

    useEffect(() => {
    const getLessons = async () => {
        try {
        const result = await API.get("/lessons");
        const { completed } = result.data;
        // setPremium(premium)
        const completedArray = completed == null ? [0] : completed;
        setDayCompleted(completedArray);
        setLoading(false);
        } catch (error) {
        console.log(error);
        }
    };
    getLessons();
    }, []);

    const openModal = (i) => {
    document.body.style.overflow = "hidden";
    setShowModal(true);
    setResetDay(i);
    };

    const closeModal = () => {
    document.body.style.overflow = "auto";
    setShowModal(false);
    };

    const resetProgress = async (day) => {
    await API.post("/reset_progress", {
        data: {
        day: day,
        },
    });
    return true;
    };

    if (loading) {
    return <Loader />;
    }

    return (
    <div className="flex flex-col items-center justify-start h-screen w-full bg-gray-100">
        <h1 className="text-4xl font-bold mt-12 mb-8">Fluency Daily</h1>
        <div className="flex flex-wrap justify-center gap-4">
        {days.map((string, i) => {
            const completedDay = dayCompleted[i];
            const backgroundColor = completedDay === 1 ? "bg-green-500" : completedDay === undefined ? "bg-blue-500" : "bg-yellow-500";
            const hoverColor = completedDay === 1 ? "hover:bg-green-600" : completedDay === undefined ? "hover:bg-blue-600" : "hover:bg-yellow-600";
            const disabledStyle = completedDay === undefined ? "opacity-50 cursor-not-allowed" : "cursor-pointer";
            return (
            <div
                key={i}
                className={`flex items-center justify-center ${backgroundColor} ${hoverColor} text-gray-800 font-bold py-2 px-4 rounded-lg ${disabledStyle}`}
                disabled={completedDay === undefined}
            >
                {dayCompleted[i] === 1 ? (
                <button onClick={() => openModal(i)} className="focus:outline-none cursor-pointer">
                    <Link to={{ pathname: `/lesson/es/${i}` }} state={{ day: +i }} className="focus:outline-none">
                    <span className="mx-1">Day {i}</span>
                    </Link>
                </button>
                ) : dayCompleted[i] >= 0 ? (
                <Link to={{ pathname: `/lesson/es/${i}` }} state={{ day: +i }} className="focus:outline-none cursor-pointer">
                    <span className="mx-1">Day {i}</span>
                </Link>
                ) : (
                <p>Day <span className="mx-1">{i}</span></p>
                )}
            </div>
            );
        })}
        </div>
        {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-500">
            <div className="bg-white rounded-lg shadow-lg p-6">
            <h3 className="text-lg font-bold mb-4">
                Clicking This Will Reset Progress For Day {resetDay}
            </h3>
            <div className="flex justify-end">
                <button
                className="mr-2 bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none"
                onClick={closeModal}
                >
                Cancel
                </button>
                <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none"
                onClick={() => resetProgress(resetDay)}
                >
                <Link to={`/lesson/es/${resetDay}`} state={{ day: resetDay }} className="focus:outline-none">
                    Reset Day {resetDay}
                </Link>
                </button>
            </div>
            </div>
        </div>
        )}
    </div>
    );
}
