import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
// import { Storage } from "aws-amplify";
// import { API } from "aws-amplify";
// import { useParams } from "react-router-dom";
// import Avatar from "../components/Avatar";
// import Button from "../components/Button";
// import Loader from "../components/Loader";

const Profile = () => {
//   const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser({bypassCache : true});
        // const userInfo = await API.get("users", `/users/${id}`);
        setUser(currentUser.attributes.email);
        // const avatarKey = `${id}-avatar.jpg`;
        // const avatarUrl = await Storage.get(avatarKey);
        // setAvatarUrl(avatarUrl);
        // setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

//   if (isLoading) {
//     return <div>Loading</div>;
//   }

  return (
    <div className="flex justify-center items-center h-screen w-full">
      <div className="max-w-md">
        {/* <Avatar src={avatarUrl} size="large" /> */}
        <h1 className="text-2xl font-bold text-gray-800 my-4">
          {user}
        </h1>
        <p className="text-gray-600"></p>
        {/* <Button label="Follow" /> */}
      </div>
    </div>
  );
};

export default Profile;