import React from "react"
import { API  , Auth} from "aws-amplify"
import { loadStripe } from "@stripe/stripe-js"

const stripePromise = loadStripe(process.env.REACT_APP_LOAD_STRIPE)

const Stripe = () => {
  const redirectToCheckout = async () => {
    const fetchSession = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const userId = user.attributes.sub;
      const apiName = "stripeAPI"
      const apiEndpoint = "/checkout"
      const body = {
        quantity: 1,
        client_reference_id: userId,
        priceId: "price_1NLRtZG7RGO7eKTFvQOvJzpv",
      }
      const session = await API.post(apiName, apiEndpoint, { body })
      return session
    }

    const session = await fetchSession()
    const sessionId = session.id
    const stripe = await stripePromise
    stripe.redirectToCheckout({ sessionId })
  }
  return <div className="p-4 hover:bg-gray-200 border-b border-gray-300 cursor-pointer" onClick={redirectToCheckout}>Upgrade</div>;

}

export default Stripe