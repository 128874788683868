import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>At Fluency Daily, we are committed to protecting the privacy and security of our customers and visitors to our website. This privacy policy explains how we collect, use, and share your personal information when you use our services and website.</p>
      <h2>Information We Collect</h2>
      <p>We may collect personal information about you when you interact with us, such as when you make a purchase, create an account, or subscribe to our newsletter. This information may include your name, email address, phone number, shipping address, billing information, and other information necessary to provide our services to you.</p>
      <h2>How We Use Your Information</h2>
      <p>We may use your personal information to:</p>
      <ul>
        <li>Process transactions and provide our services to you</li>
        <li>Communicate with you about your orders and account</li>
        <li>Send you marketing and promotional emails</li>
        <li>Improve our website and services</li>
        <li>Comply with legal obligations</li>
      </ul>
      <h2>How We Share Your Information</h2>
      <p>We may share your personal information with third-party service providers who help us provide our services to you, such as shipping and payment processing companies. We may also share your information to comply with legal obligations or to protect our rights and property.</p>
      <h2>Cookies and Other Tracking Technologies</h2>
      <p>We may use cookies and other tracking technologies to collect information about your browsing behavior and preferences. This information may be used to personalize your experience on our website and to analyze how our website is used. You can disable cookies in your browser settings, but this may affect the functionality of our website.</p>
      <h2>Your Rights</h2>
      <p>You have the right to access, correct, or delete your personal information that we hold. You may also have the right to object to or restrict our processing of your personal information. To exercise your rights, please contact us using the information provided below.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions or concerns about our privacy policy or how we handle your personal information, please contact us at [Your Company Contact Information].</p>
    </div>
  );
};

export default PrivacyPolicy;