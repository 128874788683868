import React, {useState, useEffect } from 'react'
import {useLocation} from 'react-router-dom'
import API from '../../services/API'
import Loader from '../Loader'
import UpdateQForm from './UpdateQForm'

const EachAdminDay = () => {
    const location = useLocation();
    const day = location.pathname.split('/').slice(-1)[0];
    const lang = 'spanish'
    const [qforms, setqforms] = useState([])
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        const fetchCompeltedData =  async () => {
            const wordsResult = await API.get(`/q_forms?lang=${lang}&day=${day}`)
            const words = wordsResult.data
            setqforms(words)
            setLoading(false)
            }
        fetchCompeltedData()

    },[day,lang])


    if (loading) {
        return <Loader />
    }

    
    return (
        <div>
        <UpdateQForm data={qforms} setData={setqforms} lang={lang}/>
        </div>
        )
}

export default EachAdminDay
