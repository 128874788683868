//App.js
import { Authenticator } from '@aws-amplify/ui-react';

import { RequireAuth } from './RequireAuth';
import { RequireAdminAuth } from './RequireAdminAuth';
import { Login } from './components/Login';
import { Home } from './components/Home';
import { Layout } from './components/Layout';

// Client
import Lesson from './components/Client/Lesson';
import Profile from './components/Client/Profile';

// Admin 
import AdminLang from './components/Admin/AdminLang';
import EachAdminDay from './components/Admin/EachDay';
import Languages from './components/Admin/Languages';

// General
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';

import { BrowserRouter, Routes, Route , Navigate } from 'react-router-dom';

import './App.css';

function MyRoutes() {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            index
            path="/"
            element={
              <RequireAuth>
                <div className="h-screen flex">
                  <div className="w-[20%] border-l border-gray-500">
                    <Layout />
                  </div>
                  <div className="w-[80%] bg-gray-100 overflow-y-auto">
                    <Home />
                  </div>
                </div>
              </RequireAuth>
            }
          />
          <Route
            path="/admin"
            element={
              <RequireAdminAuth>
                <div className="h-screen flex">
                  <div className="w-[20%] border-l border-gray-500">
                    <Layout />
                  </div>
                  <div className="w-[80%] bg-gray-100 overflow-y-auto">
                    <Languages />
                  </div>
                </div>
              </RequireAdminAuth>
            }
          />
          <Route
            path="/admin/es"
            element={
              <RequireAdminAuth>
                <div className="h-screen flex">
                  <div className="w-[20%] border-l border-gray-500">
                    <Layout />
                  </div>
                  <div className="w-[80%] bg-gray-100 overflow-y-auto">
                    <AdminLang />
                  </div>
                </div>
              </RequireAdminAuth>
            }
          />
          {new Array(500).fill('').map((el, i) => {
            return (
              <Route
                key={i}
                path={`/admin/es/${i}`}
                element={
                  <RequireAdminAuth> 
                  <div className="h-screen flex">
                    <div className="w-[20%] border-l border-gray-500">
                      <Layout />
                    </div>
                    <div className="w-[80%] bg-gray-100 overflow-y-auto">
                      <EachAdminDay day={i} />
                    </div>
                  </div>
                  </RequireAdminAuth>
                }
              />
            );
          })}
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <div className="h-screen flex">
                  <div className="w-[20%] border-l border-gray-500">
                    <Layout />
                  </div>
                  <div className="w-[80%] bg-gray-100 overflow-y-auto">
                    <Profile />
                  </div>
                </div>
              </RequireAuth>
            }
          />
          {/* {For each lesson for spanish now} */}
          <Route
            path="/lesson/:lang/:day"
            element={
              <RequireAuth>
                <div className="h-screen flex">
                  <div className="w-[20%] border-l border-gray-500">
                    <Layout />
                  </div>
                  <div className="w-[80%] bg-gray-100 overflow-y-auto">
                    <Lesson />
                  </div>
                </div>
              </RequireAuth>
            }
          />

          <Route path="/login" element={<Login />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsofservice" element={<TermsOfService />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;
